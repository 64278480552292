@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "../node_modules/nprogress/nprogress.css";

@font-face {
  font-family: "Lobster";
  src: url("../fonts/Lobster-Regular.woff2") format("woff2"),
    url("../fonts/Lobster-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Cabin";
  src: url("../fonts/Cabin-Regular.woff2") format("woff2"),
    url("../fonts/Cabin-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Cabin";
  src: url("../fonts/Cabin-SemiBold.woff2") format("woff2"),
    url("../fonts/Cabin-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Cabin";
  src: url("../fonts/Cabin-Bold.woff2") format("woff2"),
    url("../fonts/Cabin-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@layer components {
  /*Section*/
  .section {
    @apply py-16;
  }

  .section-container {
    @apply container mx-auto px-4 xl:max-w-screen-xl;
  }

  .section-title {
    @apply font-title text-4xl md:text-6xl;
  }

  .section-list {
    @apply mt-12 grid grid-cols-1 gap-8 md:grid-cols-2 lg:mx-10 lg:gap-16;
  }

  .fel-blue-gradient {
    @apply bg-gradient-to-b from-fel-blue to-fel-blue-light;
  }
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

#past-preview-list + #whats-this {
  box-shadow: 0 -50px 50px #462c5d;
}

.bg-home {
  background: url("/background.jpg") no-repeat top center;
  @apply object-contain;
}

#nprogress .bar {
  background: #3f2651 !important;
  height: 5px !important;
}

#nprogress .spinner-icon {
  border-top-color: #3f2651 !important;
  border-left-color: #3f2651 !important;
}

#nprogress .peg {
  box-shadow: 0 0 10px #3f2651, 0 0 5px #3f2651 !important;
}
